.whyWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}

.whyContent{
    display: flex;
      justify-content: center;
      flex-wrap: wrap;
    /* width: 100%;
    height: 100%; */
}

.whyHeader{
    font-weight: bold;
    color: black;
    padding: 40px;
    font-family: sans-serif;
}

.whyHead{
    font-weight: bold;
    color: black;
    /* padding: 40px; */
    font-family: sans-serif;
    width: 280px;
    font-size: 20px;
    margin: 0 auto;
}

.whyHead .HaSub span{
    text-align: center;
    font-family: sans-serif;
    width: 100px;
    height: 20px;
    opacity: 0;
    background: linear-gradient(to right, #549f21, #333);
    font-size: 16px;
}

.whyHead .HaSub h2{
    font-size: 15px;

    font-weight: bold;
    color: black;
    font-family: sans-serif;


}

.whyHead .HaSub h2{
    margin-top: 10px;
    font-size: 25px;
    /* white-space: nowrap; */
    font-weight: bold;
    color: black;
    font-family: sans-serif;
}

.whyHead h1{
    font-size: 16px;
    color: black;
    font-family: sans-serif;

}

.whyHead .HaSub hr{
        display: block;
        height: 8px;
        width: 150px;
        border: 0;
        background: linear-gradient(to right, #549f21, #333);
        border-radius: 16px;
        border-top: 1px solid #ccc;
        margin: 1em 0;
        padding: 0;
}

.whyWrapper img{
    display: flex;
    height: 300px;
    width: 300px;
    border-radius: 16px;
}

.whyWrapper .wrap{
    width: 300px;
    height: 500px;
    margin: 40px;
    /* padding: 100px; */
}

.button-link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 20px;
    border-radius: 16px;
    border: none;
    background: linear-gradient(to right, #549f21, #333);
    margin-top: 20px;
    text-decoration: none;
    color: white;
    font-family: sans-serif;
}