.residential-wrapper{
    height: 180vh;
    width: 100%;
    background-image: url(../components/assets/images/residential\ home.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
}

.residential-wrapper h1{
    font-size: 60px;
    font-weight: bold;
    color: white;
    width: 500px;
    letter-spacing: 1px;
    font-family: sans-serif;
    margin-bottom: 50px;
    text-shadow: 2px 2px 4px #000000;
}

.residential-wrapper button{
    font-size: 15px;
    font-weight: bold;
    color: #333;
    height: 70px;
    width: 200px;
    border-radius: 16px;
    border: none;
    font-family: sans-serif;
    margin-bottom: 50px;
}

.residential-wrapper .button-link{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 40%;
    height: 40px;
    border-radius: 16px;
    border: none;
    background-color: white;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    color: white;
    font-family: sans-serif;
}