.footerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #f5f5f5;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    border-top: 1px solid #e0e0e0;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 50px;
    /* overflow: hidden; */
}

.contact-us{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.contact-us h1{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.contact-us .head{
    font-size: 25px;
}

.services-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.services-footer h1{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.services-footer span{
    display: flex;
    flex-direction: column;
    margin-bottom: 32.2px;
}   

.social-media{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.social-media h1{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.social-media svg{
    margin-bottom: 40.2px;
    font-size: 55px;
}

.social-media svg:hover{
    color: #549f21;
}

@media screen and (max-width: 768px){
    .footerWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        background-color: #f5f5f5;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        border-top: 1px solid #e0e0e0;
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 50px;
        /* overflow: hidden; */
    }

    .contact-us{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    

    .services-footer{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .services-footer h1{
        font-size: 25px;
    }

    .social-media h1{
        display: none;
    }

    .social-media{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        /* flex-direction: column; */
        width: 100%;
    }
    
    .social-media svg{
        padding: 50px;
    }
}