.hero{
    position: relative;
    height: 100%;
    width: 100%;
}



.hero h1{
    position: absolute;
    top: 60%;
    left: 25%;
    width: 40%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    color: #fff;
    z-index: 1; 
    font-family: sans-serif;
    letter-spacing: .9px;
    text-shadow: 1px 1px 10px #000;
}

@media screen and (max-width: 776px){
    .hero{
        height: 100vh;
    }

    .hero h1{
        font-size: 2rem;
        top: 45%;
        left: 50%;
        width: 80%;
    }
    
}

@keyframes swirlEffect {
    0% {
      transform: scale(0) rotate(0deg);
      border-radius: 0%;
      opacity: 1;
    }
    50% {
      transform: scale(0.5) rotate(180deg);
      border-radius: 50%;
      opacity: 0.5;
    }
    100% {
      transform: scale(1) rotate(360deg);
      border-radius: 100%;
      opacity: 0;
    }
  }
  
  .swirl {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background-color: green;
    animation: swirlEffect 1s ease-in-out forwards;
  }
  