.HaWrap{
    /* padding: 100px; */
}

.homeAbout{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 20px; */
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 200px;
}

.HaHead{
    font-weight: bold;
    color: black;
    padding: 40px;
    margin-top: 450px;
    font-family: sans-serif;
}

.HaSub span{
    text-align: center;
    font-family: sans-serif;
    width: 100px;
    height: 20px;
    opacity: 0;
    background: linear-gradient(to right, #549f21, #333);
}

.HaSub h2{
    font-size: 24px;
    font-weight: bold;
    color: black;
    font-family: sans-serif;

}

 h1{
    font-size: 60px;
    font-weight: bold;
    color: black;
    font-family: sans-serif;

}

.HaSub hr{
        display: block;
        height: 8px;
        width: 150px;
        border: 0;
        background: linear-gradient(to right, #549f21, #333);
        border-radius: 16px;
        border-top: 1px solid #ccc;
        margin: 1em 0;
        padding: 0;
}

.homeAbout .HaLeftContent{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 15px;
    font-family: sans-serif;
    color: black;
}

.HaLeftContent h2{
    margin-bottom: 10px;
    font-size: 24px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

.homeAbout .HaMiddleContent{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.HaMiddleContent .HaTopMid{
    margin-bottom: 20px;
    font-size: 15px;
    font-family: sans-serif;
    color: black;
}

.HaMiddleContent .HaTopMid h2{
    margin-bottom: 10px;
    font-size: 16px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

.HaMiddleContent .HaBottomMid {
    font-size: 15px;
    font-family: sans-serif;
    color: black;
}


.HaMiddleContent .HaBottomMid h2 {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

.HaRightContent{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 60px; */
}

.HaRightContent .HaTopRight{
    margin-bottom: 20px;
    font-size: 15px;
    font-family: sans-serif;
    color: black;
}

.HaRightContent .HaBottomRight{
    font-size: 16px;
    font-family: sans-serif;
}

.HaRightContent  h2{
    margin-bottom: 10px;
    font-size: 16px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
    }


    .HaContent{
        width: 90%;
        margin: 0 auto;
    }

    .HaContent h2{
        font-size: 24px;
        font-weight: bold;
        color: black;
        font-family: sans-serif;
        margin-bottom: 20px;
    }

    .HaContent span{
        font-size: 16px;
        font-family: sans-serif;
        color: black;
    }

    .HaReuse{
        font-weight: bold;
        color: black;
        padding: 40px;
        font-family: sans-serif;
    }

    @media screen and (max-width: 500px){
        .homeAbout .HaLeftContent{
            width: 80%;
        }

        .homeAbout .HaMiddleContent{
            width: 80%;
        }

        .homeAbout .HaRightContent{
            width: 80%;
        }

     .head{
        font-size: 30px;
         }

}
