.estimateWrap{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../components/assets/images/green.webp);
    margin-bottom: 100px;
}

.estimateWrap h1{
    margin-bottom: 20px;
    font-size: 50px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

.estimateBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #549f21, #333);
    border-radius: 30px;
    padding: 5px 10px;
    cursor: pointer;
    color: black;
    font-family: sans-serif;
    height: 60px;
    width: 400px;
    border: none;
}

.estimateWrap a{
    text-decoration: none;
    color: white;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.estimateWrap button:hover{
    background: #333;
    transition: ease-in-out 0.2s;
}

@media screen and (max-width: 768px){
    .estimateWrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #f5f5f5;
        margin-bottom: 80px;

    }

    .estimateWrap h1{
        font-size: 30px;
        font-family: sans-serif;
        color: black;
        font-weight: bold;
        margin-top: 30px;
    }

    .estimateBtn{
        font-size: 15px;
        font-weight: bold;
        color: #333;
        height: 70px;
        width: 200px;
        border-radius: 16px;
        border: none;
        font-family: sans-serif;
        margin-bottom: 50px;
    }
    
}