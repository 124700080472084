.greenWrapper{
    height: 100%;
    width: 100%;
    background-image: url(../components/assets/images/green.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.left{
    width: 60%;
    padding: 20px;

}

.greenHead{
    margin-top: 100px;
    margin-bottom: 50px;
    padding: 20px;
}

.greenHead h1{
    font-family: sans-serif;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 2px;
}

.greenContent{
    font-family: sans-serif;
    font-size: 14px;
    width: 100%;
    margin: 10px;

}

.greenContent span{
    padding: 10px;
}

.greenContent p{
    margin-top: 10px;
    padding: 10px;

}


.greenWrapper .button-link {
color: #fff;
background: linear-gradient(to right, #549f21, #333);
padding: 10px 20px;
text-decoration: none;
font-family: sans-serif;
letter-spacing: 2px;
border-radius: 16px;
font-weight: bold;
transition: background-color 0.3s ease;
margin-left: 190px;
}

.button-link {
    color: #fff;
    background: linear-gradient(to right, #549f21, #333);
    padding: 10px 20px;
    text-decoration: none;
    font-family: sans-serif;
    letter-spacing: 2px;
    border-radius: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    }

.button-link:hover {
background-color: #0056b3;
}



.right{
    position: relative;
    margin-top: 50px;
}

.right img{
    border-radius: 16px;
    margin-bottom: -220px;
    position: absolute;
    bottom: 1px;
    height: 1100px;
    right: 0;
    /* right: -149px; */
    /* transform: translate(-50%, -50%); */
    width: 400px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 7));
    z-index: 1;
}

.imgBack{
    border-radius: 16px;
    margin-bottom: -220px;
    position: absolute;
    bottom: 8px;
    height: 1100px;
    right: 20px;
    /* right: -149px; */
    /* transform: translate(-50%, -50%); */
    width: 400px;
    background-color: #0b3013;
}

@media screen and (max-width: 500px){
.greenHead h1{
        font-size: 20px;
        margin-right: 40px;
    }    

.greenContent{
    font-size: 15px;
}
}

@media screen and (max-width: 900px){
    .greenWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .greenWrapper .button-link{
        margin-left: 85px;
    }

    .left{
        text-align: center;
        width: 80%;
        padding: 20px;
        margin-right: 20px;
    }
    .greenHead h1{
        width: 100%;
        font-size: 20px;
        text-align: center;
    }    

    .greenHead{
        width: 100%;
        text-align: center;
    }

    .greenContent{
        width: 100%;
        text-align: center;
        font-size: 14px;
    }

    .greenWrapper button{
        width: 200px;
    }

    .right img{
        display: none;
    }

    .imgBack{
        display: none;
    }
    
}