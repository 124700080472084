/* Form Wrapper Styles */
.FormWrap {
    width: 80%;
    max-width: 600px; /* Limit the max width for larger screens */
    min-height: 100vh;
    margin: 0 auto;
    /* background-color: #f8f8f8; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Form Heading Styles */
  .formHead, .formSub {
    text-align: center;
    font-family: 'Arial', sans-serif; /* Consistent font-family */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .formHead {
    color: #333;
    font-size: 2.5rem; /* Responsive font size */
    margin-bottom: 10px;
  }
  
  .formSub {
    color: #000;
    font-size: 1.25rem; /* Responsive font size */
  }

  
  /* Input Field Styles */
.message {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  
   label input {
    width: 500px;
    padding: 10px;
    border-radius: 5px;
  }

  /* Submit Button Styles */
  .submit {
    width: 90%;
    margin-left: 10px;
    padding: 10px 20px;
    background: linear-gradient(to right, #549f21, #333);    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .submit:hover {
    background: #333; /* Darker shade for hover state */
  }
  
  /* Success Message Styles */
  .success-message {
    color: #28a745; /* Bootstrap's success color for consistency */
    margin-top: 20px;
  }
  
  @media screen and (max-width: 768px){
    .FormWrap input {
      width: 300px;
    }

    
  }