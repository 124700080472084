.navbar{
    position: fixed;
    width: 100%;
    height: 350px;
    /* background-color: #36588f; */
    background: linear-gradient(to bottom, #36588f, #36588f, transparent);
    /* overflow: hidden; */
    z-index: 2;
}

.navbar .topNav{
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

.navbar .topNav .contact{
    color: black;
    font-size: 15px;
    margin: 5px;
}

.navbar .topNav .contact a{
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    margin: 25px;
    background: linear-gradient(to right, #549f21, #333);
    border-radius: 30px;
    padding: 5px 10px;
}

.navbar .topNav .contact a:hover{
    background: #333;
}

.navbar .topNav .contact svg{
    color: white;
    margin-bottom: -2px;
}
   

.navbar .topNav .contact span{
    background: linear-gradient(to right, #549f21, #333);
    border-radius: 30px;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
    font-family: sans-serif;
}

.navbar .topNav .contact span:hover{
    background: #333;
}

.navbar .topNav .socials{
    margin: 30px;
    font-size: 30px;
    color: white;
}   

.navbar .topNav .socials svg{
    color: white;
    text-decoration: none;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
}

.navbar .topNav .socials svg:hover{
    color: #549f21;
}

.navbar .bottomNav{
    height: 150px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.navbar .bottomNav .flex img.logo {
    display: flex;
    width: 300px;
    height: 200px;
    margin: 0 auto;
    /* mix-blend-mode: lighte=; */
}

.navbar .bottomNav .flex{
    display: flex;
    flex-direction: column;
}

.navbar .bottomNav .flex .links{
    display: flex;
    position: relative;
    z-index: 1;
    width: 100vw;
    justify-content: space-evenly;
    /* background-color: #36588f; */
    align-items: center;    
    margin-top: 20px;
    text-shadow: 1px 1px 10px #333;
}

.navbar .bottomNav .flex .links a{
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    font-family: sans-serif;
    letter-spacing: .1px;
    font-weight: bolder;
}

/* .navbar .bottomNav .flex .links .dropDown{
    color: white;
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: .1px;
    text-decoration: none;
    font-family: sans-serif;
    cursor: pointer;
} */

.dropDown {
    position: relative;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: bolder;
    letter-spacing: .1px;
    text-decoration: none;
    font-family: sans-serif;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 16px;
    opacity: .9;
}

.dropdown-content .dropdown-links {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: .4rem;
}

.dropDown:hover .dropdown-content {
    display: block;
    font-size: 1rem;

}

.dropdown-content a:hover {
    /* background-color: #f1f1f1; */
}

.navbar .bottomNav .flex .links .dropDown:hover{
    color: #549f21;
    /* font-size: 1.5rem; */

}

.navbar .bottomNav .flex .links a:hover{
    color: #549f21;
}



.navbar .bottomNav .hamburger{
    display: none;
    position: absolute;
    right: 20px;
    top: 0px;
}

.menu{
    height: 600px;
    width: 100%;
}

.menu a{
    color: white;
    /* font-size: 1.5rem; */
    text-decoration: none;
    font-family: sans-serif;
    display: block;
}

.popover{
    position: absolute; 
    top: 20px;
    left: 0;
    width: 358px;
    height: 100%;
    z-index: 2; 
    margin-left: -53px;
}

.popover a{
    color: white;
    font-size: 1.05rem;
    text-decoration: none;
    font-family: sans-serif;
    display: block;
    padding: 20px;
    background-color: #36588f;
    text-align: center;
    border-bottom: 1px solid #36588f;
}

.popoverTwo{
    position: absolute; 
    top: 20px;
    left: 0;
    width: 358px;
    height: 100%;
    z-index: 2; 
    margin-left: -28px;
}

.popoverTwo a{
    color: white;
    font-size: 1.05rem;
    text-decoration: none;
    font-family: sans-serif;
    display: block;
    padding: 20px;
    background-color: #36588f;
    text-align: center;
    border-bottom: 1px solid #36588f;
}


 

ul {
    background-color: #36588f;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.home{
    /* overflow: hidden; */
        height: 160vh;
        width: 100vw;
        margin-bottom: 100px;
        background-image: url(../components/assets/images/hero.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
}

/* .home::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 140vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
} */



@media screen and (min-width: 761px){
    ul {
        display: none;
    }

    li{
        display: none;
    }
}

@media screen and (max-width: 776px){
    a{
        display: none;
    }

    .dropDown{
        display: none;
    }

    .navbar{
        height: 180px;
    }

    .navbar .topNav{
        height: 40px;
    }

    .navbar .topNav .contact{
        display: none;
    }

    .navbar .topNav .socials svg{
        display: none;
    }

    .navbar .topNav .socials svg:hover{
        color: #549f21;
    }



    .navbar .bottomNav{
        height: 60px;
    }

    .navbar img.logo{
        display: flex;
        width: 200px;
        height: 150px;
        position: absolute;
        left: 0%;
        top: -50%;
        }

    .navbar .bottomNav .hamburger{
        display: block;
    }
    
}


