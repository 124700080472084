.stepsWrap{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.stepsWrap h1{
    margin-bottom: 20px;
    font-size: 50px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

.stepsWrap table{
    border: 1px solid black;
    width: 95%;
    height: 200px;
}

.stepsWrap table tr{
    border: 1px solid black;
}

.stepsWrap table tr td{
    border: 1px solid black;
    padding: 10px;
    font-size: 15px;
    font-family: sans-serif;
    color: black;
    text-align: center;
    line-height: 30px;
}

.stepsWrap table .stepHead td{
    color: #008000;
    font-weight: 700;
    font-size: 20px;
}

@media screen and (max-width: 768px){
   .stepsWrap{
    display: none;
   }
    
}