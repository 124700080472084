

.Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 80px;
    width: 35%;
    height: 50%;
    float: right;
    margin-top: 350px;
    margin-right: 60px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.Form .formHead{
    font-size: 30px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
    font-family: sans-serif;
}

.Form .formSub {
    text-align: center;
    font-family: sans-serif;
    font-size: 15px;
    color: black;
    margin-bottom: 20px;

}

.Form .inputFlex{
    display: flex;
    flex-wrap: wrap;
    width: 120%;
}





label{
    padding-bottom: 10px;
    padding-right: 20px;
    font-size: 15px;
    font-family: sans-serif;
    color: black;
    display: flex;
    flex-direction: column;

}

input{
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    /* background-color: #36588f; */
    background-color: white;
    border: 1.4px solid black;
    font-size: 16px;
    width: 60%;

}

.Form .contact {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Form .message {
    width: 119.5%;
}

.Form .contact input, .Form .contact text {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    width: 80%;
    font-family: sans-serif;
}

.Form .contact .submit {
    background: linear-gradient(to right, #549f21, #333);
    border-radius: 30px;
    padding: 5px 5px;
    cursor: pointer;
    margin-left: 50px;
    color: white;
        font-family: sans-serif;
}

.Form .contact button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 1000px) {
    
    
}

@media screen and (max-width: 760px){
    .Form{
        position: 
        absolute;
        top: 15%;
        left: 15%;
        width: 45%;
        height: 70%;
        padding: 50px 50px;

    }

    .Form .formHead{
        font-size: 15px;
        text-align: center;
        margin-top: 70px;
    }

    .Form .formSub{
        font-size: 10px;
        text-align: center;

    }

    .Form .contact .submit{
        margin-left: 20px;   
    }

    .Form .message {
        width: 110.5%;
    }

    
} 

@media screen and (max-width: 500px){
    .Form{
        position: 
        absolute;
        top: 15%;
        left: 15%;
        width: 45%;
        height: 70%;
        padding: 50px 50px;

    }

    .Form .formHead{
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
    }

    .Form .formSub{
        font-size: 10px;
        text-align: center;

    }

    .Form .contact .submit{
        margin-left: 20px;   
    }

    .Form .message {
        width: 110.5%;
    }
    
}