.whyTable{
    width: 100%;
    height: 200%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 2rem 0; */
    background-color: white;
}

.whyTable span{
    font-size: 60px;
    font-weight: bold;
    color: #333;
    font-family: sans-serif;
    margin-bottom: 50px;

}

.whyTable table {
    /* padding: 20px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: #f5f5f5;
    font-family: sans-serif;
}

.whyTable table tr {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    height: 500px;
    width: 220px;
    padding: .5px;
}

.whyTable table td{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.whyTable table .TableGrey{
    background-color: grey;
    height: 100px;
    border: none;
}

.whyTable table th {
    height: 200px;
    width: 220px;
    background-color: rgb(59, 182, 77);
    border: #f5f5f5 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: white;
    font-weight: bold;
}

.whyTable table .description{
    height: 360px;
    width: 218px;
    /* background-color: rgb(59, 182, 77); */
    border: #f5f5f5 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border: .1px solid #333;
}


.whyTable .areaMap{
    margin-top: 100px;
    background-image: url('../components/assets/images/areaMap.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow: hidden; */
    height: 90%;
    width: 100%;
}

.areaMap{
    background-image: url('../components/assets/images/areaMap.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow: hidden; */
    height: 90%;
    width: 100%;

}

@media screen and (max-width: 1000px){
    .whyTable span{
        font-size: 40px;
        font-weight: bold;
        color: #333;
        font-family: sans-serif;
        margin-bottom: 50px;
    
    }
    
    .whyTable table {
        /* padding: 20px; */
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: #f5f5f5;
        font-family: sans-serif;
    }
    
    .whyTable table tr {
        display: flex;
        flex-direction: column;
        /* justify-content: center;
        align-items: center; */
        height: 500px;
        width: 220px;
        padding: .5px;
    }
    
    .whyTable table td{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .whyTable table .TableGrey{
        background-color: grey;
        height: 100px;
        border: none;
    }
    
    .whyTable table th {
        height: 200px;
        width: 220px;
        background-color: rgb(59, 182, 77);
        border: #f5f5f5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        color: white;
        font-weight: bold;
    }
    
    .whyTable table .description{
        height: 360px;
        width: 218px;
        /* background-color: rgb(59, 182, 77); */
        border: #f5f5f5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        border: .1px solid #333;
    }
    
    
    .whyTable .areaMap{
        margin-top: 100px;
        height: 100%;
        overflow: hidden;
    }
}