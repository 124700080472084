.areaWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 2000px; */
}

.areaWrapper .areaHead{
    margin-top: 200px;
    padding: 100px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    font-family: sans-serif;
    /* margin-bottom: 50px; */
    text-align: center;
}

.areaWrapper .areaHead h2{
    font-size: 20px;
    font-family: sans-serif;
    color: black;
    margin-top: 20px;
}
.areaWrapper .areaList{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
    color: black;
    width: 100%;
    background-color: transparent;
    text-align: left;
}

.areaWrapper .areaList ul{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    flex-wrap: wrap;
    /* margin-bottom: 100px; */
    color: black;
    width: 25%;
    background-color: transparent;
}


.areaWrapper .areaList ul li{
    display: flex;
    list-style-type: style;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    color: black;
}

.areasServed{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../components/assets/images/areaMap.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1000px){
    .areaWrapper{
        /* margin-top: 900px; */
    }

    .areaWrapper .areaHead{
        font-size: 30px;
    }
    
    .areaWrapper .areaList{
        width: 100%;
    }
    
    .areaWrapper .areaList ul{
        width: 100%;
    }
    
    .areaWrapper .areaList ul li{
        width: 100%;
    }

    
}

@media screen and (max-width: 400px){
    .areaWrapper{
       /* margin-bottom: 100px; */
    }

    .areaWrapper .areaHead{
        font-size: 25px;
    }
    
    .areaWrapper .areaList{
        width: 100%;
    }
    
    .areaWrapper .areaList ul{
        width: 100%;
    }
    
    .areaWrapper .areaList ul li{
        width: 100%;
    }
}


@media screen and (max-width: 764px){
    .areaWrapper{
        /* margin-top: 2000px; */
    }

    .areaWrapper .areaHead{
        font-size: 20px;
    }
    
    .areaWrapper .areaList{
        width: 100%;
    }
    
    .areaWrapper .areaList ul{
        width: 100%;
    }
    
    .areaWrapper .areaList ul li{
        width: 100%;
    }

    
}
