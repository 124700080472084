.design-hero{
    height: 130vh;
    width: 100%;
    background-image: url(../components/assets/images/design.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.design-hero-text h1 {
    color: white;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}


.design-hero .button-link{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 40%;
    height: 40px;
    border-radius: 16px;
    border: none;
    background-color: white;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    color: white;
    font-family: sans-serif;
}