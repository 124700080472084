.elevateWrapper{
    margin-top: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../components/assets/images/Elevate.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.elevateWrapper span{
    font-size: 60px;
    font-weight: bold;
    color: black;
    font-family: sans-serif;
    margin-bottom: 50px;
}

@media screen and (max-width: 1000px){
    .elevateWrapper{
        margin-top: 1000px;
    }

    .elevateWrapper span{
        font-size: 30px;
    }
    
}

@media screen and (max-width: 764px){
    .elevateWrapper{
        margin-top: 2700px;
    }

    .elevateWrapper span{
        font-size: 20px;
    }
    
}