.makeoverWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    margin-bottom: 80px;
    padding: 20px;
}

.makeoverWrap img{
    display: flex;
    height: 500px;
    width: 500px;
    border-radius: 16%;
    /* padding: 100px; */
    padding: 20px;
}

.makeoverWrap h1{
    font-size: 40px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
    margin-bottom: 20px;
}

.makeoverWrap span{
    font-size: 15px;
    line-height: 30px;
    font-family: sans-serif;
    color: black;
    margin-bottom: 20px;
}

.makeoverWrap hr{
    display: block;
    height: 8px;
    width: 150px;
    border: 0;
    background: linear-gradient(to right, #549f21, #333);
    border-radius: 16px;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;

}

@media screen and (max-width: 768px){
    .makeoverWrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #f5f5f5;
        margin-bottom: 80px;

    }

    .makeoverWrap img{
        display: flex;
        height: 300px;
        width: 300px;
        border-radius: 16%;
        padding: 30px;
    }

    .makeoverWrap h1{
        font-size: 30px;
        font-family: sans-serif;
        color: black;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .makeoverWrap span{
        font-size: 15px;
        line-height: 30px;
        font-family: sans-serif;
        color: black;
        margin-bottom: 20px;
    }

    .makeoverWrap hr{
        display: block;
        height: 8px;
        width: 150px;
        border: 0;
        background: linear-gradient(to right, #549f21, #333);
        border-radius: 16px;
        border-top: 1px solid #ccc;
        margin: 1em 0;
        padding: 0;
    }
    
}